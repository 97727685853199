// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
   version: '1.200.2',
   production: false,
   notification: true,
   auth0Config: {
      clientID: 'yFhU6PFbYRG9272CRoJjklBc0YowXSlt',
      domain: 'kcmsbrasil.auth0.com',
      responseType: 'token id_token',
      audience: 'https://develop.soluserp.kcms.com.br',
      redirectUri: 'http://soluserp-dev.azurewebsites.net',
      scope: 'openid profile email',
   },
   apiBaseUrl: 'https://erp-main-api-dev.azurewebsites.net/',
   menuServiceBaseUrl: 'https://menu-dev.kcms.app/',
   menuServiceWaiterBaseUrl: 'https://garcom-dev.kcms.app/',
   menuServiceCheckerBaseUrl: 'https://checker-dev.kcms.app/',
   deliveryServiceBaseUrl: 'https://pede-dev.kcms.app/',
   orderCenterBaseUrl: 'https://ordercenter-api-dev.azurewebsites.net/',
   erpHubUrl: 'https://main-hub-dev.azurewebsites.net/Erp',
   orderCenterHubUrl: 'https://main-hub-dev.azurewebsites.net/OrderCenter',
   permissionsObjectName: 'https://soluserp.kcms.com.br/claims/permissions',
   groupsObjectName: 'https://soluserp.kcms.com.br/claims/groups',
   rolesObjectName: 'https://soluserp.kcms.com.br/claims/roles',
   cryptoKey: 'NcVcqQIHLdXsPpUW',
   cryptoIV: 'NcVcqQIHLdXsPpUW',
   intercomAppId: 'ew8gy634',
   wootricAccountToken: 'NPS-5c84d1dd',
   orderCenterRing: 'old_telephone.mp3',
   cloudPaymentUrl: 'https://stone-payment-api-dev.azurewebsites.net/',
   financialApiUrl: 'https://erp-financial-api-dev.azurewebsites.net/',
   deliveryServiceApiUrl: 'https://pedemais-api-dev.azurewebsites.net/',
   googleApiKey: 'AIzaSyAifx7AaE1TQH260DuEXTqu-_Fm0u3vT2g',
   easyChoppApiUrl: 'https://easychopp-api-prod.azurewebsites.net/',
   ifoodDeliveryTrackingApiUrl: 'https://meupedido.ifood.com.br/',
   adsense: {
      adClient: 'ca-pub-0402701089458425',
      slot: 1436596987,
      adFormat: 'horizontal',
      fullWidthResponsive: true,
      debug: true,
   },
   appInsights: {
      instrumentationKey: '0b7fa2df-fc42-4158-a993-909c6a3a7542',
   },
};
